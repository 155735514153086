<template>
  <div center class="mb-5 page-title text-center text-md-left">
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: "page-title",
  props: {
    title: String,
  },
};
</script>
<style>
h2 {
  font-size: 30px;
  color: #006494;
}
</style>
